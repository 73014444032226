@include tablet {
    .contenedor-anuncios {
        @include grid(3, 2rem);
    }
}

.anuncio {
    background-color: lighten($gris, 5);
    border: 1px solid darken($gris, 10);
    margin-bottom: 2rem;

    .contenido-anuncio {
        padding: 2rem;
    }

    h3, p {
        margin: 0;
    }
 
}
.precio {
    color: $verde;
    font-weight: $bold;
    font-size: 3rem;
}

.iconos-caracteristicas {
    display: flex;
    list-style: none;
    padding: 0;
    max-width: 40rem;

    li {
        flex: 1;
        display: flex;

        img {
            flex:  0 0 3rem;
            margin-right: 1rem;
        }
        p {
            font-weight: $black;
        }
    }
}