@include tablet {
    .iconos-nosotros {
        @include grid(3, 2rem);
    }
}

.icono {
    text-align: center;
    img {
        height: 10rem;
    }
    h3 {
        text-transform: uppercase;
    }
}