.boton-amarillo {
    @include boton($amarillo, inline-block);
}

.boton-amarillo-block {
    @include boton($amarillo, block);
}

.boton-verde {
    @include boton($verde, inline-block);
}